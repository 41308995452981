/* You can add global styles to this file, and also import other style files */

/*NGX-QUILL required imports for rich text*/
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.svg-icon {
  transform: scale(1.75);
}

@import "@angular/material/theming";
@import "./tjs-theme.scss";

a {
  color: black;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto";
}

p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

p.normal {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-size: 1em;
  line-height: 1em;
}

ng-invalid.ng-dirty {
  border-color: indianred;
}

ng-valid.ng-dirty {
  border-color: mediumseagreen;
}

quill-editor.ng-invalid.ng-touched {
  border: 1px solid red;
}

.bg-secondary {
  color: black;
}

.bg-dark {
  color: #ffffff;
  background-color: map-get($tjs-primary, default);
}

.bg-light {
  color: map-get($tjs-primary, default);
  background-color: #ffffff;
}

.drop-shadow {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.height-adaptive {
  height: -webkit-calc(100vh - 72px);
  height: -moz-calc(100vh - 72px);
  height: calc(100vh - 72px);
}

.posting_p .tab-custom .left_list_panel {
  box-shadow: 2px 2px 10px 0px #484848;
}

.posting_p .tab-custom .left_list_panel .nav-tabs {
  direction: ltr;
  border: none;
}

.posting_p .tab-custom .left_list_panel .nav-tabs li {
  float: none;
  position: relative;
}

.posting_p .tab-custom .left_list_panel .nav-tabs li.active > .active-arrow {
  display: none;
}

.posting_p .tab-custom .left_list_panel .settings_list {
  margin: 0;
}

.posting_p
.tab-custom
.left_list_panel
.settings_list
.search_bar
.input-group
input {
  border-radius: 0;
  height: 46px;
  outline: none;
  border: none;
  border-bottom: 1px solid #cecece;
}

.posting_p
.tab-custom
.left_list_panel
.settings_list
.search_bar
.input-group
span
button {
  border-radius: 0;
  height: 46px;
  outline: none;
  border: none;
  background: #64dd17;
}

.posting_p .tab-custom .left_list_panel .settings_list .filters {
  width: 100%;
  border-bottom: 1px solid #cecece;
}

.posting_p .tab-custom .left_list_panel .settings_list .filters .sort_btn,
.posting_p .tab-custom .left_list_panel .settings_list .filters .show_btn {
  width: 50%;
  border-radius: 0;
  border: none;
  margin-right: -3px;
  background: #fff;
}

.posting_p .tab-custom .left_list_panel .settings_list .filters .sort_btn p,
.posting_p .tab-custom .left_list_panel .settings_list .filters .show_btn p {
  position: relative;
  top: 4px;
  font-size: 13px;
  text-transform: uppercase;
  color: #64dd17;
}

.posting_p
.tab-custom
.left_list_panel
.settings_list
.filters
.sort_btn
p
span,
.posting_p
.tab-custom
.left_list_panel
.settings_list
.filters
.show_btn
p
span {
  font-size: 20px;
  position: relative;
  top: 2px;
}

.posting_p
.tab-custom
.left_list_panel
.settings_list
.filters
a.collapsed > p > span {
  transform: rotate(-90deg);
  transition: 0.5s all ease-in-out;
}

.posting_p .tab-custom .left_list_panel .settings_list .filters a > p > span {
  transition: 0.5s all ease-in-out;
  transform: rotate(0deg);
}

.posting_p .tab-custom .left_list_panel .settings_list .filters .sort_btn {
  border-right: 1px solid #cecece;
}

.posting_p .tab-custom .left_list_panel .nav li {
  border-bottom: 1px solid transparent;
}

.posting_p .tab-custom .left_list_panel .nav li a {
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom-color: #cecece;
  margin: 0;
  background: #fff;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  padding: 5px;
}

.posting_p .tab-custom .left_list_panel .nav li:hover > a {
  background: #ededed;
}

.posting_p .tab-custom .left_list_panel .nav li.active > a {
  background: #ededed;
  color: #1976d2;
}

.sidemenu_pipeline.nav li:hover > a,
.sidemenu_pipeline.nav li.active > a {
  background: #1976d2 !important;
  color: #fff !important;
}

.posting_p .tab-custom .left_list_panel .nav .media .media-left img {
  height: 55px;
  min-height: 55px;
  min-width: 55px;
  position: relative;
  margin-top: 8px;
}

.posting_p .tab-custom .left_list_panel .nav .media .media-left.feedback_img_cl img {
  height: 75px;
  min-height: 75px;
  min-width: 75px;
  position: relative;
  margin-top: 8px;
}

a {
  text-decoration: none;
}

.color-primary {
  color: map-get($hjj-primary, default) !important;
}

.color-accent {
  color: map-get($tjs-accent, default) !important;
}

.color-warn {
  color: map-get($tjs-warn, default) !important;
}

section#philos {
  background: #fff;
}

section#footer {
  background: #fff;
}

.full-width {
  width: 100%;
}

.toast-top-auto-width {
  width: auto;
}

.bg-dark .heading {
  color: #555555;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  line-height: 22px;
}

.mat-input-infix.mat-form-field-infix {
  color: #000;
}

li.list-group-item.animate-repeat.re-animate.ng-star-inserted {
  color: #000;
}

label.mat-checkbox-layout {
  white-space: normal !important;
  float: left;
}

input.ng2-tag-input__text-input {
  color: #000;
  background: #fafafa;
  width: 100%;
}

.colorblack {
  color: #000;
}

.mat-stepper-horizontal form {
  margin: 0;
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-infix {
  width: 100%;
}

.mat-stepper-horizontal-line {
  display: none;
}

.has-error .form-control:focus {
  border: none !important;
  box-shadow: none !important;
}

.form-control:focus {
  border: none !important;
  box-shadow: none !important;
}

.form-control {
  border: none !important;
  box-shadow: none !important;
}

.mytitle {
  color: #000;
  word-wrap: break-word;
  display: block;
}

.mytitle.form-head {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.mat-student-stepper .mat-horizontal-content-container {
  padding: 0;
}

.student-reg-form-header {
  background: #f9fafb;
  padding: 5px 0 10px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.panel-body .mat-form-field {
  margin: 13px 0;
}

.panel-body .width-auto {
  width: 100%;
  margin: 0;
}

.panel-body .mat-form-field .cal-lbl {
  margin-top: 20px;
}

.panel-accord {
  line-height: 25px;
}

.panel-body .width-auto .input-cust-width {
  width: 70%;
}

.panel-body .width-auto .mat-input-infix {
  padding-bottom: 0;
}

.cal-cell-row.cal-header.ng-star-inserted {
  min-height: auto;
}

.cal-days .ng-star-inserted {
  min-height: 0;
}

.md-whiteframe-5dp.homepage-bottomdiv {
  height: 289px;
  max-height: 289px !important;
}

.caps {
  text-transform: uppercase;
}

.mat-form-field .mat-error {
  font-size: 14px;
  margin: 0;
}

.panel-body .width-auto .mat-input-infix.mat-form-field-infix {
  border: 0px !important;
  padding: 0px !important;
}

.tcl .mat-input-infix.mat-form-field-infix {
  border: 0px !important;
  padding: 0px !important;
}

.login_re .mat-dialog-actions.return_login {
  float: right;
}

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px;
}

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px;
}

.btn.find_out_more {
  margin: 10px;
}

.page-scroll.get_started {
  text-transform: uppercase;
  color: rgb(33, 33, 33) !important;
  font-weight: 700 !important;
  text-decoration: none;
  border-radius: 3px;
  padding: 0px 6px;
  min-height: 36px;
  line-height: 36px;
  font-size: 14px;
  min-width: 88px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.01em;
}

.get_started:hover,
.get_started:focus {
  text-decoration: none !important;
  outline: none !important;
}

.get_started.color-cl {
  color: #fff !important;
  padding: 0px 6px;
  min-height: 36px;
  line-height: 36px;
  font-size: 14px;
  min-width: 88px;
  letter-spacing: 0.01em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 3px;
}

.dialog-controls .panel-body {
  padding: 0;
}

.bold-fonts {
  font-weight: 600 !important;
}

.test.mat-form-field:after {
  content: "";
  display: block;
  clear: both;
}

.help-block.error_mt {
  margin-top: -15px;
  font-size: 14px;
}

.caps.mat-button {
  font-weight: 700 !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
  letter-spacing: 0.01em;
}

:foucs {
  outline: none;
}

.background_ch {
  background: rgb(25, 118, 210) !important;
}

.background_ch .mat-toolbar-row {
  height: auto !important;
}

.background_ch .nav-buttons button {
  background: #fff;
  color: #000;
  border: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  border-radius: 3px;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  white-space: nowrap;
  min-width: 88px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  outline: none;
  font-size: 14px;
}

.submit-bb button {
  position: absolute;
  right: 15px;
  top: 15px;
}

.posting_popup {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  min-height: auto;
  max-height: auto;
}

.posting_popup .mat-dialog-container {
  padding: 0px !important;
  background: none;
  box-shadow: none;
  overflow: inherit;
}

.primary_secondary {
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
}

.primary_secondary .row:hover {
  background: #f5f5f5;
}

.address_post_edit {
  padding: 18px 0px;
}


.posting_create .mat-toolbar-tools .mat-icon-button {
  float: right;
}

.popup_class_new {
  width: 600px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .popup_class_new {
    width: auto;
    margin: auto;
  }
}

.popup_class_new .modal-content .mat-toolbar-single-row {
  background: none;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  height: 58px;
}

.popup_class_new .modal-content .mat-toolbar-single-row h1 {
  font-weight: 700;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  float: left;
}

.popup_class_new .close_bt {
  height: auto;
  width: auto;
  line-height: 1px;
  color: #000;
  opacity: 0.2;
}

.popup_class_new .modal-footer button {
  text-transform: uppercase;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  padding: 0 6px;
  margin: 6px 8px;
  line-height: 36px;
  min-height: 36px;
  background: 0 0;
  white-space: nowrap;
  min-width: 88px;
  font-size: 14px;
}

.popup_class_new .modal-footer .save-bt {
  background-color: rgb(3, 155, 229);
}

.popup_class_new .modal-footer .Cancel-btt {
  background-color: rgb(244, 81, 30) !important;
}

.addbutton .view_all {
  text-transform: uppercase;
  margin: 6px 8px;
  min-height: 36px;
  min-width: 88px;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  background-color: rgb(25, 118, 210) !important;
  padding: 0px 6px;
}

.timesheet-view .top_tl h1 {
  font-weight: 700;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
}

.search-timesheet-btn.apply_clear {
  padding-top: 15px;
}

.pddin {
  margin: 18px 0;
}

.ft_padding {
  padding-bottom: 0px !important;
}

.overview_partition .mat-form-field {
  margin: 0px 0px 0px 5px !important;
}

.overview_partition .mat-form-field-wrapper {
  margin-bottom: -15px !important;
}

.posting_create .popup_sc {
  margin: 0px;
  padding: 0px;
}

.custom_popup {
  width: 66.66% !important;
}

.custom_popup .top_name {
  border-radius: 6px 6px 0px 0px;
}

.custom_popup .bgpopup_in {
  background: rgb(250, 250, 250);
}

.custom_popup .bgpopup_in .mat-card {
  background: none !important;
}

.custom_popup .footer_popUp {
  padding: 5px 0px 0px 0px !important;
}

.custom_popup .footer_popUp .invite-b {
  background-color: rgb(3, 155, 229) !important;
}

.custom_popup .footer_popUp button {
  padding: 0px 5px !important;
  font-weight: 700;
}

.custom_popup .bgpopup_in .mat-elevation-z4 {
  box-shadow: 0px !important;
}

.create_pipeline_popup .mat-dialog-container {
  padding: 0px !important;
  background: none;
  z-index: 10 !important;
  overflow: inherit;
}

.ng2-dropdown-menu[_ngcontent-c22] {
  z-index: 999999 !important;
  position: absolute;
}

.create_pipeline_popup .submit-bb {
  padding: 25px 0px !important;
  background: #fff !important;
}

.create_pipeline_popup .panel-body {
  background: #fafafa;
  padding: 16px;
}

.create_pipeline_popup .background_ch {
  min-height: 45px !important;
  text-align: left;
}

.create_pipeline_popup .background_ch .mat-toolbar-row {
  height: 45px !important;
}

.create_pipeline_popup .submit-bb .Continue {
  color: #333;
  font-weight: 700;
  border: 0px;
  background: none;
  position: absolute;
  display: inline-block;
  top: 10px;
  left: inherit;
  text-transform: uppercase;
  padding: 6px 15px;
  border-radius: 3px;
}

.create_pipeline_popup .submit-bb .back {
  color: #333;
  font-weight: 700;
  border: 0px;
  background: none;
  position: absolute;
  display: inline-block;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
  padding: 6px 15px;
  border-radius: 3px;
}

.create_pipeline_popup .submit-bb .back:focus,
.create_pipeline_popup .submit-bb .Continue:focus,
.create_pipeline_popup .submit-bb .back:hover,
.create_pipeline_popup .submit-bb .Continue:hover {
  background-color: rgba(158, 158, 158, 0.2);
  outline: none;
}

.cancel_b {
  width: AUTO !important;
}

.cancel_b i {
  color: #fff !important;
  opacity: 1 !important;
}

.create_pipeline_popup .mat-checkbox-layout {
  width: 33.33% !important;
  display: inline-block !important;
}

.create_pipeline_popup .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
}

.create_pipeline_popup .link-opp {
  padding: 20px;
}

.create_pipeline_popup .mat-dialog-container {
  box-shadow: inherit !important;
}

.create_pipeline_popup .posting_create {
  width: 87%;
  margin: 0px auto;
}

.link_studentse .mat-form-field {
  margin: 0px !important;
}

@media screen and (max-width: 768px) {
  .custom_popup {
    max-width: inherit !important;
    width: 100% !important;
    top: 0px !important;
    position: absolute !important;
  }
}

mat-grid-tile {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 6px 6px -2px rgba(0, 0, 0, 0.12);
  background-color: rgba(250, 250, 250, 0.5);
}

.mat-tab-label {
  min-width: 110px !important;
}

.pipe-list {
  line-height: 40px;
}

.edit_button_main {
  float: right;
  z-index: 99999;
}

.fab-speed-dial.pipe_fab {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
  z-index: 99999;
}

.pipeline_p .sidemenu_pipeline {
  height: 100%;
  overflow-y: auto;
}

.height-adaptive {
  height: -webkit-calc(100vh - 75px);
  height: -moz-calc(100vh - 75px);
  height: calc(100vh - 75px);
}

.drop-shadow.sidemenu_pipeline_right {
  height: -webkit-calc(100vh - 72px) !important;
  height: -moz-calc(100vh - 72px) !important;
  height: calc(100vh - 72px) !important;
  overflow-y: auto;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
}

.settings_list .search_pipeline {
  position: relative;
  display: block;
  border-collapse: separate;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  margin: 10px 15px;
}

.sidemenu_pipeline_right .mat-tab-label-container .mat-tab-list .mat-tab-label {
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
}

.acd .postings-panel-title {
  padding: 10px;
  font-size: 18px;
  display: inline-block;
}

.acd .panel .accordian-caret {
  margin: 0px;
  border: 0px;
  padding: 0px;
  box-shadow: none;
}

.acd .panel .accordian-caret:focus {
  outline: none;
  box-shadow: none;
  z-index: 10;
}

.acd .panel .accordian-caret {
}

body.emp_reg .cdk-overlay-container {
  z-index: 1525 !important;
}

.navbar-fixed-top {
  z-index: 20 !important;
}

.pipeline-modal-error {
  margin-bottom: 0;
  margin-top: 12px;
}

.posting_page
.tab-custom
.left_list_panel
.settings_list
.search_bar
.input-group
input {
  border-bottom: 1px solid #cecece !important;
  margin: 0px !important;
}

.posting_page .sort_cl {
  display: inline-block;
  padding: 8px 0px;
}

.posting_page .sort_cl label {
  display: inline-block;
  float: left;
}

.side_fab {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9;
}

.posting_page .mat-tab-label {
  font-weight: 700;
  font-size: 16px;
}

.side_fab .sub_button button {
  width: 40px;
  height: 40px;
  background-color: #fff !important;
  color: #333;
}

.side_fab .sub_button .warn {
  background-color: rgb(255, 87, 34) !important;
  color: #fff !important;
}

.side_fab .sub_button button span {
  padding: 0px;
}

.submit_cancel button {
  margin: 0px 10px;
}

.posting_input input {
  padding: 5px 10px;
}

.table_section input {
  width: 85%;
  padding: 10px;
}

.posting_delete_popup .mat-dialog-container {
  box-shadow: inherit !important;
  background-color: inherit !important;
}

.eye_hover {
  position: relative;
}

.eye_hover:hover .view_detail_s {
  opacity: 0.6;
  bottom: 0px;
  z-index: 9;
}

.footer_popUp.posting_pop {
  background: #fafafa;
  padding-bottom: 6px !important;
  border-radius: 0px 0px 5px 5px;
}

.news-feeds-school .mat-list-item {
  height: auto !important;
}

.mat-list-base .mat-list-item {
  font-size: 16px !important;
}

.news-feeds-school p {
  opacity: 0.6;
}

.tab-group .mat-tab-label.mat-tab-label-active {
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  opacity: 1;
}

.tab-group .mat-tab-label {
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  opacity: 1;
}

.panel.panel-default {
  box-shadow: none;
  border: 0px;
}

.modal-footer .caps.mat-button {
  position: relative;
}

.modal-footer .mat-progress-spinner {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.padding_color {
  padding: 15px;
  background: #fff;
  margin-bottom: 15px;
}

.timesheet_search .dataTables_filter input,
.timesheet_search .dataTables_wrapper select {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.background_none_cl {
  background-color: inherit !important;
  box-shadow: inherit !important;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.panel-body .input_width_74 .width-auto .input-cust-width {
  width: 74.8%;
}

.skills-profile tag-input-form {
  width: 100%;
}

.admin_user_page {
  width: 98%;
  margin-top: 1px;
}

.admin_user_page.edit_group_user {
  width: auto;
  margin-top: 0px;
}

.instructions_cl {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 20px;
}

.admin_user_page .dataTables_filter input,
.admin_user_page .dataTables_length select {
  display: inline-block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.admin_user_page .dataTables_filter input:hover,
.admin_user_page .dataTables_filter input:focus,
.admin_user_page .dataTables_length select:hover,
.admin_user_page .dataTables_length select:focus {
  box-shadow: none;
  border: 1px solid #ccc;
  outline: none;
}

.admin_user_page .dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 1px solid #ddd;
  margin-left: -1px;
  border-radius: 0px;
}

.admin_user_page
.dataTables_wrapper
.dataTables_paginate
.paginate_button.disabled {
  border: 1px solid #ddd !important;
}

.admin_user_page
dataTables_wrapper
.dataTables_paginate
.paginate_button.disabled:hover {
  border: 1px solid #ddd !important;
}

.admin_user_page .dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
  border: 1px solid #ddd;
  padding: 8px 14px 9px 14px !important;
  margin-left: -1px;
}

.admin_popup .modal-content {
  border: 0px;
}

.admin_popup .modal-header h1 {
  float: left;
  margin-bottom: 0px;
}

.admin_popup .modal-header .mat-icon-button.close_bt {
  float: right;
}

.admin_popup .modal-header .mat-icon-button.close_bt:hover,
.admin_popup .modal-header .mat-icon-button.close_bt:focus {
  float: right;
  background-color: none !important;
}

.modal-footer .edit_user_btn {
  color: #383838;
  font-weight: 700;
  text-transform: uppercase;
}

.ng2-dropdown-menu[_ngcontent-c33] {
  z-index: 999999999;
}

.fa-check.progress-pill {
  color: #fff;
}

.star-icon:before {
  top: -3px;
}

pre.short_desc.ng-star-inserted {
  background: #fff;
  border: none;
}

.shadow_none {
  box-shadow: none !important;
}

.proflie-page #we_overview .overview_partition h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 270px !important;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}

.temp .messages {
  padding: 15px;
  padding-left: 55px;
}

@keyframes shrink {
  50% {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.large-card {
  height: 820px;
}

.medium-card {
  height: 400px;
}

.small-card {
  height: 250px;
}

.overview-sub-card {
  height: 140px;
}

.max-dashboard {
  width: 1280px;
}

$range-intersection-color: #66bb6a;

@if variable-exists(range-silder-color) {
  $range-intersection-color: $range-silder-color;
}

$range-outer-color: #bdbdbd;

@if variable-exists(range-outside-color) {
  $range-outer-color: $range-outside-color;
}

ngx-mat-range-slider {
  height: 60px;

  .mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
  }

  .mat-slider-thumb-label-text {
    transform: rotate(-45deg) !important;
    opacity: 1 !important;
  }

  .mat-slider-track-wrapper {
    background: $range-intersection-color;
  }

  .slider-line-cover-wrapper {
    top: 39px;
    z-index: -1;
    display: table;

    .slider-line-cover {
      background-color: $range-outer-color !important;
      border-bottom: 1px solid $range-outer-color !important;
      display: table-cell;
      width: 50%;
    }

    .slider-line-cover-init {
      background-color: $range-intersection-color !important;
      border-bottom: 1px solid $range-intersection-color !important;
      display: table-cell;
      width: 50%;
    }
  }

  div.outer {
    display: table;
    border-top: 2px solid red;
    width: 100%;
  }

  div.middle:nth-of-type(1) {
    display: table-row;
    font-size: 5px;
  }

  div.middle:nth-of-type(2) {
    display: table-row;
    font-size: 10px;

    span {
      font-size: 10px;
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }

  div.inner {
    display: table-cell;
    align-content: center;
    align-items: center;
    text-align: center;
  }
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
  width: 48px !important;
  height: 48px !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
  font-size: 9px !important;
  text-align: center !important;
}

.mat-slider-horizontal .mat-slider-thumb-label {
  right: -24px !important;
  top: -58px !important;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  margin: 0px !important;
}

.mat-display-2, .mat-typography .mat-display-2 {
  margin: 0px !important;
}

.mat-display-4, .mat-typography .mat-display-4 {
  margin: 0px !important;
}

.mat-display-1, .mat-typography .mat-display-1 {
  margin: 0px !important;
}

.mat-display-3, .mat-typography .mat-display-3 {
  margin: 0px !important;
}

.mat-h2, .mat-title, .mat-typography h2 {
  margin: 0px !important;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  margin: 0px !important;
}

.mat-list-item, .mat-card {
  font-family: "Roboto";
}

.mat-card-title {
  font-family: "Roboto" !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  margin: 0px !important;
}

.padBottom8px {
  padding-bottom: 8px;
}

.padBottom12px {
  padding-bottom: 12px;
}

.padBottom5px {
  padding-bottom: 5px;
}

.padBottom3px {
  padding-bottom: 3px;
}
